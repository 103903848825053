@import '../../../../styles/mixins.scss';

.payment {
  display: flex;
  justify-content: flex-start;

  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_items {
    display: flex;
    flex-wrap: wrap;
  }

  &_item {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &_select {
    width: 100%;
  }
}
