@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  text-align: center;

  &_icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      #d62efc -7.91%,
      #b32de9 52.75%,
      #6a35b3 143.37%
    );
    border-radius: 100px;
    padding: 18px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_name {
    margin-top: 20px;
    margin-bottom: 15px;
    color: $c-white;
  }

  &_text {
    @include additional2($c-grey-300);
  }

  &_white {
    color: $c-white;
    font-weight: bold;
  }
}
