@import '../../../styles/mixins.scss';

.title {
  position: relative;
  @include h1($c-white);

  span {
    &:first-child {
      color: transparent;
      -webkit-text-stroke: 0.8px rgb(212, 0, 255);
    }

    &:last-child {
      position: absolute;
      top: -4px;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.white {
  color: rgba(255, 255, 255, 1);
}

.purple {
  color: rgb(212, 0, 255);
}

.h2 {
  @include h2($c-white);
}

.h3 {
  @include h3($c-white);
}
