@import '../../styles/mixins.scss';

.banner {
  max-height: 300px;
  position: relative;
  height: 300px;

  &_img {
    width: 100%;
    object-fit: cover;
    height: 300px;
    border-radius: 20px;
  }

  &_bg {
    background-color: $c-grey-100;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    border-radius: 20px;
  }
}

.detail {
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: -20px;
  height: 160px;
  width: 160px;
  border-radius: 10px;
  padding: 5px;
  background-color: $c-white;

  &_img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  @include media-xs {
    flex-direction: column;
  }
}

.actions {
  display: flex;
  align-items: center;

  &_item {
    margin-left: 15px;
    padding: 12px;
    border-radius: 100%;
    cursor: pointer;
    transition: all $transitionDefaultDuration ease;

    &:hover {
      svg,
      svg path {
        fill: $c-pink;
      }
    }

    svg {
      height: 25px;
      width: 25px;
    }
  }
}

.tabs {
  &_tablist {
    display: flex;
    margin-top: 40px;
    border-bottom: 1px solid rgba(229, 232, 235, 0.2);
    margin-bottom: 20px;
  }

  &_tab {
    @include additional($c-grey-300);
    margin-right: 30px;
    padding-bottom: 10px;
    outline: none;
    cursor: pointer;

    &[aria-selected='true'] {
      color: $c-white;
      border-bottom: 1px solid $c-white;
    }
  }
}
