@import '../../styles/mixins.scss';

.h1 {
  margin-bottom: 33px;
  margin-top: -60px;
}

.description {
  @include subtitle($c-white);
  margin-bottom: 61px;
}

.section {
  &_top {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;

    &_bg {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      left: 0;
      right: 0;
      z-index: 1;

      @include media-sm {
        display: none;
      }

      &_mobile {
        display: none;

        @include media-sm {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: 1;
          left: 0;
          right: 0;
        }
      }
    }

    &_wrap {
      z-index: 2;
    }
  }

  &_info {
    background: linear-gradient(180deg, #08002c 0%, rgba(9, 5, 55, 0) 65.43%);
    padding-top: 101px;
    padding-bottom: 136px;

    @include media-xs {
      padding-bottom: 80px;
      padding-top: 70px;
    }

    &_wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      @include media-sm {
        grid-template-columns: 1fr;
      }
    }
  }

  &_items {
    position: relative;
    padding-bottom: 400px;

    @include media-sm {
      padding-bottom: 200px;
    }

    @include media-xs {
      padding-bottom: 150px;
    }

    &.collections {
      background: linear-gradient(
        180deg,
        $c-dark 0%,
        $c-dark 0.01%,
        #210640 104.61%
      );
      padding-top: 133px;
      padding-bottom: 0;

      @include media-xs {
        padding-top: 80px;
      }
    }

    &_bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  &_tables {
    padding-top: 240px;

    @include media-xs {
      padding-top: 120px;
    }

    &_wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px;

      @include media-lg {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
      }

      @include media-sm {
        grid-template-columns: 1fr;
        grid-gap: 80px;
      }
    }
  }

  &_table {
    &_title {
      @include subtitle3($c-white);
      margin-bottom: 21px;
    }

    &_item {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_join {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;

    @include media-xs {
      height: 70vh;
    }

    &_wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 200px;

      @include media-sm {
        flex-direction: column;
        align-items: center;
      }

      @include media-xs {
        margin-bottom: 100px;
      }
    }

    &_btn {
      @include media-sm {
        margin: auto;
      }
    }

    &_img {
      @include media-sm {
        width: 120px;
        margin-bottom: 40px;
      }
    }

    .container {
      z-index: 2;
    }

    &_bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
    }

    &_info {
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include media-sm {
        margin-left: 0;
      }
    }

    &_title {
      @include subtitle2($c-white);
      margin-bottom: 18px;

      @include media-sm {
        width: 100%;
      }
    }

    &_description {
      color: $c-white;
      margin-bottom: 31px;
    }
  }

  &_mint {
    background: $c-dark;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 280px;
    padding-bottom: 600px;

    @include media-md {
      padding-bottom: 400px;
    }

    @include media-sm {
      padding-bottom: 300px;
    }

    @include media-xs {
      padding-top: 100px;
      padding-bottom: 200px;
    }

    &_bg {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      left: 0;
      right: 0;
      z-index: 0;

      @include media-sm {
        display: none;
      }

      &_mobile {
        display: none;

        @include media-sm {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: 1;
          left: 0;
          right: 0;
        }
      }
    }

    &_title {
      margin-bottom: 32px;
    }

    &_container {
      z-index: 1;
    }

    &_moon {
      position: absolute;
      top: 137px;
      left: -110px;
      filter: drop-shadow(0px 4px 50px #350576);
      height: 200px;
      width: 200px;
    }

    &_description {
      @include subtitle($c-white);
      margin-bottom: 46px;

      @include media-sm {
        margin-bottom: 30px;
      }
    }

    &_info {
      margin-top: 85px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      @include media-sm {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin-top: 44px;
      }
    }
  }
}

.container {
  @include container();
}

.tabs {
  display: flex;
  flex-direction: column;

  &_tablist {
    display: flex;
    justify-content: center;
    margin-bottom: 41px;
    z-index: 2;

    &_wrap {
      display: flex;
      overflow-x: auto;
      margin-right: -20px;
      padding-right: 20px;

      @include media-xs {
        margin-right: -15px;
        padding-right: 15px;
      }
    }
  }

  &_tabpanel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    &_collection {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 13px;

      @include media-md {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media-sm {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-xs {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }
  }

  &_btn {
    margin-top: 61px;

    &_wrap {
      display: flex;
      justify-content: center;
    }
  }
}

.h2 {
  margin-bottom: 67px;
  text-align: center;
}

.footer {
  background: $c-dark;
}

.main_wrapper {
  position: relative;
}
