@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.wrapper {
  position: relative;
}

.main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  margin-top: 40px;
  margin-bottom: 20px;

  @include media-md {
    grid-gap: 15px;
  }

  @include media-sm {
    grid-template-columns: 1fr;
  }

  &_img {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      max-height: 407px;
    }

    img {
      width: 100%;
    }

    @include media-sm {
      padding: 0 120px 60px 120px;
    }

    @include media-xs {
      padding: 0 0 60px 0;
    }
  }

  &_items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 32px;
    }
  }

  &_item {
    height: 200px;
  }
}

.slider {
  margin-top: 172px;

  @include media-sm {
    margin-top: 80px;
  }
}

.top {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 13px;
  justify-content: flex-end;
  margin-bottom: 20px;

  @include media-xs {
    display: flex;
    flex-wrap: wrap;
  }
}

.properties {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;

  @include media-md {
    grid-template-columns: 1fr;
  }

  &_items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 7px;
    justify-content: space-between;
  }

  &_collapse {
    margin-bottom: 10px;
  }
}
