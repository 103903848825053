@import '../../../styles/mixins.scss';

.numbers {
  display: flex;
  margin-top: 20px;

  &_item {
    margin-right: 30px;

    &_value {
      @include additional($c-white);
      margin-bottom: 5px;
    }

    &_name {
      @include additional2($c-grey-300);
      text-transform: uppercase;
    }

    @include media-sm {
      margin-top: 10px;
    }
  }

  @include media-sm {
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
