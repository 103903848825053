@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.h1 {
  margin-bottom: 14px;
  text-align: center;

  @include media-sm {
    margin-bottom: 22px;
  }
}

.content {
  margin-top: 180px;

  @include media-sm {
    margin-top: 20px;
  }
}

.text {
  color: $c-white;
  line-height: 24px;
  text-align: center;
  margin-bottom: 60px;

  @include media-sm {
    margin-bottom: 52px;
  }
}

.tabs {
  &_tablist_wrap {
    display: flex;

    @include media-xs {
      justify-content: center;
    }
  }
}

.table {
  margin-top: 20px;

  @include media-sm {
    margin-top: 40px;
  }

  &_tabs {
    margin-top: 151px;

    &_tablist {
      margin-bottom: 27px;
    }
  }

  &_title {
    display: flex;
    align-items: center;
  }

  &_row {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    justify-content: space-between;
    padding: 21px 5px;
    border-bottom: 1px solid #353d6d;
    align-items: center;

    @include media-lg {
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &_titles {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    @include subtitle3($c-white);
    font-size: 16px;
    text-shadow: none;
    padding-bottom: 30px;
    border-bottom: 1px solid #353d6d;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;

    @include media-lg {
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
      width: 800px;
      font-size: 14px;
    }
  }

  &_rows {
    @include media-lg {
      width: 800px;
      font-size: 12px;
    }
  }

  &_img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-left: 12px;
  }
}
