@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.title {
  text-align: center;
  margin-bottom: 42px;
  margin-top: 30px;

  @include media-sm {
    margin-bottom: 30px;
  }
}
