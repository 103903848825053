@import './mixins.scss';

html {
  scroll-behavior: smooth;
}

body {
  background-repeat: repeat-y;
  font-family: 'Gilroy-Medium';
  background: linear-gradient(
    180deg,
    rgba(6, 5, 63, 1) 0%,
    rgba(2, 24, 110, 1) 30%,
    rgba(10, 0, 43, 1) 85%,
    rgba(6, 5, 63, 1) 100%
  );
  background-size: 100% 2000px;
}

// Tabs
.tab-active .tab-item-active {
  background: $c-pink;
}

.tab {
  outline: none;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
}

.main-tabs {
  display: flex;
  background: linear-gradient(
    92.07deg,
    #05062c -98.82%,
    #0d1361 39.77%,
    #0b1265 162.76%
  );
  border-radius: 30px;
}

.main-tab-active .main-tab-item-active {
  background: linear-gradient(
    92.31deg,
    #3567f2 0%,
    #1f51db 65.54%,
    #001b67 132.97%
  );
  border-radius: 27.5px;
}

.main-tab {
  outline: none;
  width: 100%;
}

.table-tabs {
  display: flex;
}

.table-tab {
  margin-left: 30px;
  @include subtitle($c-grey-300);
  outline: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

.table-tab-active .table-tab-item-active {
  @include subtitle($c-white, 'bold');
}

// Datepicker
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 15px;
  fill: white;
}

.rs-picker-toggle-textbox {
  background-color: rgba(38, 31, 138, 0.5);
  outline: none;
  font-size: 16px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 100%;
  align-items: center;
  display: flex !important;
  background-color: rgba(38, 31, 138, 0.5) !important;
  border: none;
}

.rs-picker-toggle-textbox::placeholder {
  color: transparent;
}

.rs-picker-toggle-active {
  box-shadow: none;
}

.rs-picker-menu {
  margin-top: 10px;
  z-index: 99999 !important;
}

.rs-picker-toggle-clean {
  top: 16px !important;
  right: 40px !important;
}

.rs-picker-toggle-value {
  color: $c-white !important;
  font-size: 16px;

  &[aria-placeholder] {
    color: $c-grey-300 !important;
  }
}

.rs-picker-date .rs-picker-toggle-value {
  color: $c-white !important;
}

.rs-picker-toolbar-ranges,
.rs-picker-daterange-predefined {
  display: none;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 20px;
}

.rs-picker-daterange-panel,
.rs-calendar-time-dropdown,
.rs-calendar-time-dropdown-column-title,
.rs-calendar-month-dropdown-row:not(:last-child) {
  background-color: $c-dark;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: $c-blue;
}

.rs-btn-primary,
.rs-btn-primary:hover {
  background-color: $c-pink;
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  background-color: $c-pink;
  opacity: 0.5;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: $c-blue;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: $c-pink;
}

.rs-picker-daterange-calendar-group {
  @include media-sm {
    flex-direction: column;
    display: flex;
    height: auto;
    min-width: 291px;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.popup-overlay {
  z-index: 9999 !important;
  overflow: scroll;
}

.rs-picker-menu {
  background-color: $c-dark;
}
