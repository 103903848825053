@import '../../styles/mixins.scss';

.h2 {
  margin-bottom: 29px;
}

.description {
  @include subtitle($c-white);
  margin-bottom: 36px;

  @include media-sm {
    margin-bottom: 30px;
  }
}

.cost {
  width: 564px;
  margin: auto;

  @include media-sm {
    width: 100%;
  }
}

.section {
  &_top {
    text-align: center;
    margin-top: 159px;

    @include media-xs {
      margin-top: 80px;
    }
  }
}

.container {
  @include container();
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.slider {
  margin-top: 100px;

  @include media-sm {
    margin-top: 77px;
  }

  &_recommended {
    margin-top: 151px;
    padding-bottom: 500px;

    @include media-sm {
      margin-top: 80px;
      padding-bottom: 200px;
    }

    &_item {
      height: 100%;
    }
  }

  &_wrap {
    width: 60%;

    @include media-sm {
      width: 100%;
      margin-top: 60px;
    }

    :global {
      .slick-arrow {
        background: rgba(38, 31, 138, 0.5);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        width: 60px;
        height: 60px;
      }

      .slick-next {
        @include media-sm {
          right: 0;
        }
      }

      .slick-prev {
        @include media-sm {
          left: 0;
        }
      }
    }
  }

  &_flex {
    display: flex;
    justify-content: space-between;

    @include media-sm {
      flex-direction: column-reverse;
    }
  }

  &_info {
    width: 35%;

    @include media-sm {
      width: auto;
      display: flex;
      overflow-x: scroll;
      margin-right: -20px;
      padding-right: 20px;
    }

    @include media-xs {
      margin-right: -15px;
      padding-right: 15px;
    }

    &_item {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-sm {
        margin-bottom: 0;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &_item {
    height: 380px;
    padding: 15px;

    &_img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &_dots {
    display: flex;
    justify-content: center;

    @include media-sm {
      margin-top: 30px;
    }
  }

  &_dot {
    margin-left: 13px;

    &:first-child {
      margin-left: 0;
    }
  }
}
