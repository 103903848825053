@import '../../../styles/mixins.scss';

.item {
  padding-right: 13px;
  display: block;
  width: 100%;
  min-width: 227px;
  margin-top: 12px;

  span {
    color: $c-white;
    transition: $transitionDefaultDuration ease;
    opacity: 0;
  }

  &.active {
    & > div:first-child {
      background: #0a42ea;
    }
  }

  &_box {
    position: relative;
    padding: 12px 12px 12px 20px;
    cursor: pointer;
    border-radius: 0 8px 8px 0;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_wrap {
    display: flex;
    align-items: center;
  }

  &_img {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    img {
      width: 100%;
    }
  }

  &_list {
    padding: 29px 53px 12px;

    &_link {
      cursor: pointer;
      display: block;
      margin-bottom: 21px;
      font-size: 15px;
    }
  }

  &_arrow {
    transition: $transitionDefaultDuration ease;

    &.active {
      transform: rotate(180deg);
    }
  }
}
