@import '../../styles/mixins.scss';

.settings {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-sm {
    width: 100%;
  }

  &_avatar {
    width: 150px;
    height: 150px;
    border: 3px solid $c-white;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_upload {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.form {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  &_name {
    @include subtitle($c-white, 'bold');
    margin-bottom: 20px;
    text-align: center;
  }

  &_btn {
    margin: 30px auto 0 auto;
  }

  &_input {
    width: 100%;
    height: 60px;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    &_wrap {
      text-align: center;
    }

    &_icon {
      cursor: pointer;
      width: 36px;
      height: 36px;
      background-color: $c-dark;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }
}

.hidden {
  display: none;
}

.wallets {
  margin-top: 50px;

  @include media-sm {
    margin-top: 81px;
    width: 100%;
  }

  &_title {
    margin-bottom: 44px;
    text-align: center;
  }

  &_items {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;

    @include media-sm {
      width: 100%;
    }
  }

  &_item {
    margin-bottom: 13px;

    &_connect {
      display: flex;
      background-color: $c-dark;
      justify-content: space-between;
      align-items: center;
      padding: 23px 20px;
      border-radius: 25px;
      height: 90px;

      @include media-xs {
        flex-direction: column;
        height: auto;
      }
    }

    &_name {
      @include additional2($c-white);

      @include media-xs {
        margin-bottom: 15px;
      }
    }
  }
}
