@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    100.69deg,
    #241e87 8.56%,
    rgba(36, 30, 135, 0) 111.52%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  padding: 20px;

  @include media-xs {
    flex-direction: column;
  }

  &_img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;

    @include media-xs {
      width: 100%;
      height: 120px;
      margin-bottom: 15px;
    }
  }

  &_info {
    margin-left: 30px;

    @include media-xs {
      margin-left: 0;
    }
  }

  &_title {
    @include subtitle2($c-white);
    margin-bottom: 18px;

    @include media-xs {
      margin-bottom: 15px;
    }
  }

  &_description {
    color: $c-white;
    line-height: 24px;
  }
}
