@import '../../../styles/mixins.scss';

.item {
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: auto !important;
  border-bottom: 1px solid $c-grey-300;
  transition: all $transitionDefaultDuration ease;

  &:hover {
    opacity: 0.8;
  }

  &:last-child {
    border-bottom: none;
  }

  img {
    height: 20px;
    width: 20px;
  }

  span {
    color: $c-white;
    margin-left: 10px;
    white-space: nowrap;
  }
}
