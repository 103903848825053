@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: rgba(38, 31, 138, 0.5);
  backdrop-filter: blur(64px);
  border-radius: 25px;
  padding: 25px 40px;

  &_text {
    @include subtitle($c-grey-50);
  }

  &_arrow {
    display: flex;
    align-items: center;
  }
}
