@import '../../styles/mixins.scss';

.h1 {
  margin-bottom: 78px;
  margin-top: -60px;
  color: $c-white;
  font-family: 'Gemina';
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;

    @include media-xs {
      display: block;
    }
  }

  h1 {
    font-size: 260px;

    span {
      &:last-child {
        top: -30px;

        @include media-xs {
          top: -4px;
        }
      }
    }

    @include media-md {
      font-size: 195px;
    }

    @include media-sm {
      font-size: 140px;
    }

    @include media-xs {
      font-size: 90px;
    }
  }

  img {
    margin: 0 10px;
    animation: rotate404 linear 20s infinite;

    @include media-md {
      height: 220px;
    }

    @include media-sm {
      height: 180px;
    }

    @include media-xs {
      height: 90px;
    }
  }
}

.description {
  margin-bottom: 17px;
}

.text {
  margin-bottom: 30px;
  color: $c-white;
  line-height: 24px;
}

.section {
  &_top {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &_bg {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      left: 0;
      right: 0;

      @include media-sm {
        display: none;
      }

      &_mobile {
        display: none;

        @include media-sm {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: 1;
          left: 0;
          right: 0;
        }
      }
    }

    &_wrap {
      z-index: 2;
    }
  }
}

.container {
  @include container();
}

@keyframes rotate404 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
