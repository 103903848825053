@import '../../styles/mixins.scss';

.form {
  width: 500px;
  margin-bottom: 20px;

  @include media-md {
    width: 100%;
  }

  .input {
    margin-bottom: 20px;

    &_wrap {
      height: 60px;
    }

    &_password {
      margin-bottom: 30px;
    }
  }

  .h1 {
    margin-bottom: 50px;
    text-align: center;
  }

  .link {
    font-weight: bold;
    font-family: 'Gilroy-Bold';
    color: $c-white;
    text-decoration: underline;
  }

  .btn {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .signin {
    color: $c-white;
    text-align: center;
  }
}

.connect {
  margin-bottom: 40px;
  background: $c-dark;
  border-radius: 25px;
  padding: 32px 100px;
  text-align: center;

  @include media-xs {
    padding: 32px 50px;
  }

  &_title {
    color: #8289ac;
    margin-bottom: 38px;

    @include media-xs {
      margin-bottom: 30px;
    }
  }

  &_items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 65px;
    justify-content: center;

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 23px;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include media-xs {
      margin: auto;
    }

    &_icon {
      width: 26px;
      height: 26px;

      img {
        width: 100%;
      }
    }

    &_name {
      color: $c-white;
      margin-left: 13px;
    }
  }
}

.checkbox {
  margin-bottom: 10px;
}
