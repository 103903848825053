@import '../../../styles/mixins.scss';

.slider {
  &_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;
  }

  &_title {
    @include media-xs {
      font-size: 17px;
    }
  }

  &_item {
    padding-right: 13px;

    &:last-child {
      padding-right: 0;
    }

    @include media-xs {
      padding-right: 0;
    }
  }

  &_arrows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    @include media-xs {
      margin-left: 10px;
    }
  }
}
