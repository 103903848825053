@import '../../styles/mixins.scss';

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

.snackbar {
  max-height: 60px;
  min-width: 250px;
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  padding: 16px;
  border-radius: 8px;
  background-color: $c-grey-100;
  color: $c-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.5s;

  &_error {
    background-color: $c-pink;
    color: $c-white;
  }

  @include media-xs {
    right: 15px;
    left: 15px;
  }
}
