@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.h1 {
  margin-bottom: 60px;
  text-align: center;

  @include media-sm {
    margin-bottom: 37px;
  }
}

.content {
  margin-top: 180px;

  @include media-sm {
    margin-top: 20px;
  }
}

.actions {
  display: flex;
  justify-content: center;

  @include media-sm {
    flex-direction: column;
  }

  &_item {
    background: linear-gradient(
      100.69deg,
      #241e87 8.56%,
      rgba(36, 30, 135, 0) 111.52%
    );
    border: 1px solid $c-white;
    border-radius: 25px;
    @include subtitle3($c-white);
    margin-left: 13px;
    padding: 18px 20px;

    &:first-child {
      margin-left: 0;
    }

    @include media-sm {
      margin-left: 0;
      margin-bottom: 10px;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 17px;
  margin-top: 150px;

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-sm {
    grid-template-columns: 1fr;
    margin-top: 60px;
  }

  &_item {
    @include media-md {
      height: 230px;
    }

    @include media-sm {
      height: auto;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  @include media-sm {
    margin-top: 40px;
  }
}
