@import '../../../styles/mixins.scss';

.item {
  height: 15px;
  border: 1px solid $c-grey-300;
  border-radius: 10px;

  &_progress {
    height: 100%;
    background-color: $c-pink;
    border-radius: 10px;
  }
}
