@import '../../styles/mixins.scss';

.h1 {
  margin-bottom: 33px;
}

.description {
  @include subtitle($c-white);
  margin-bottom: 61px;

  @include media-sm {
    margin-bottom: 30px;
  }
}

.section {
  &_top {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;

    &_bg {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      left: 0;
      right: 0;

      @include media-sm {
        display: none;
      }

      &_mobile {
        display: none;

        @include media-sm {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: 1;
          left: 0;
          right: 0;
        }
      }
    }

    &_wrap {
      z-index: 2;
    }
  }

  &_info {
    background: linear-gradient(180deg, #08002c 0%, rgba(9, 5, 55, 0) 65.43%);
    padding-top: 101px;
    padding-bottom: 136px;

    @include media-sm {
      padding-top: 77px;
      padding-bottom: 80px;
    }
  }
}

.cost {
  width: 564px;
  margin: auto;

  @include media-sm {
    width: 100%;
  }
}

.container {
  @include container();
}

.footer {
  background: $c-dark;
}

.main_wrapper {
  position: relative;
}

.h3 {
  margin-bottom: 40px;

  @include media-sm {
    text-align: center;
    margin-bottom: 34px;
  }
}

.collection {
  &_items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;

    @include media-lg {
      width: 100%;
      margin-top: 30px;
    }

    @include media-sm {
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
    }
  }

  &_item {
    display: grid;
    grid-template-columns: 299px 299px 1fr;
    grid-gap: 46px;
    align-items: center;

    @include media-lg {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: space-between;
    }

    @include media-sm {
      width: 100%;
    }

    &_info {
      position: relative;

      @include media-lg {
        width: 45%;
      }

      @include media-sm {
        width: 100%;
        margin-top: 30px;
      }
    }

    &_subtitle {
      @include additional2($c-grey-300, 'bold');
      margin-bottom: 9px;
    }

    &_title {
      @include title($c-white, 'bold');
      margin-bottom: 15px;
    }

    &_description {
      @include additional($c-white);
    }

    &_wrap {
      justify-content: center;

      @include media-lg {
        width: 45%;
      }

      @include media-sm {
        width: 100%;
      }

      img {
        height: 200px;
        object-fit: cover;
      }
    }

    &_category {
      width: 80px;
      height: 36px;
      background-color: $c-blue;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include additional2($c-white, 'bold');
      border-radius: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.slider {
  margin-top: 163px;
  padding-bottom: 500px;

  &_item {
    height: 100%;
  }

  @include media-md {
    margin-top: 0;
  }

  @include media-sm {
    padding-bottom: 200px;
  }
}
