@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 17px 62px;
  cursor: pointer;
  font-family: 'Gilroy-Bold';
  white-space: nowrap;

  &_text {
    color: $c-white;
  }

  &_active {
    background: linear-gradient(
      92.31deg,
      #3567f2 0%,
      #1f51db 65.54%,
      #001b67 132.97%
    );
    border-radius: 27.5px;
  }
}
