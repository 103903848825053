@import '../../../../styles/mixins.scss';

.wrap {
  &_top {
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  &_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.back {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);

  img {
    width: 100%;
    height: 100%;
  }
}

.btn {
  width: 100%;
  margin-top: 50px;
}

.item {
  display: flex;

  &_img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }

  &_wrap {
    margin-left: 20px;
  }

  &_collection {
    @include additional2($c-blue);
  }

  &_description {
    @include additional2($c-grey-300);
    margin-top: 10px;
  }

  &_name {
    @include additional($c-white);
    margin-top: 10px;
  }
}
