@import '../../../styles/mixins.scss';

.input {
  margin-bottom: 30px;

  &_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &_name {
    color: $c-white;
  }

  &_price {
    display: flex;
  }

  &_wrap {
    width: 100%;
  }
}

.select {
  &_price {
    width: 30%;
    margin-right: 10px;

    @include media-xs {
      width: 100%;
    }
  }
}

.info {
  padding-top: 40px;
  border-top: 1px solid $c-grey-300;
  margin-bottom: 40px;

  &_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  span {
    @include additional2($c-grey-300);

    &.info_subtitle {
      @include additional($c-white);
    }
  }
}
