@import '../../../styles/mixins.scss';

.dropdown {
  position: relative;

  &_top {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &_list {
    display: none;
    position: absolute;
    top: calc(100% + 7px);
    left: 0;
    background-color: rgba(10, 0, 44, 0.9);
    padding: 13px 16px;
    border-radius: 10px;
    width: 100%;
    z-index: 999;

    &:hover {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      height: 7px;
    }

    &_item {
      padding-bottom: 15px;
      cursor: pointer;
      color: $c-white;
      display: flex;
      align-items: center;

      &:hover {
        color: $c-pink;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &_icon {
        margin-left: 10px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_label {
    color: $c-white;
    margin-right: 20px;
  }

  &:hover .dropdown_list {
    display: block;
  }
}
