@import '../../styles/mixins.scss';

.info {
  position: relative;
  border: 3px solid rgba(53, 103, 242, 0.2);
  border-radius: 15px;
  padding: 23px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_subtitle {
    @include additional2($c-grey-300, 'bold', 18px);
    margin-bottom: 9px;
    text-transform: uppercase;
  }

  &_title {
    @include title($c-white, 'bold');
    margin-bottom: 15px;

    @include media-sm {
      margin-top: 40px;
    }
  }

  &_description {
    color: $c-white;
    line-height: 24px;
    margin-bottom: 10px;

    @include media-sm {
      margin-bottom: 0;
    }
  }

  &_category {
    position: absolute;
    top: 23px;
    right: 20px;
    background: $c-blue;
    border-radius: 10px;
    @include additional2($c-white, 'bold');
    text-transform: uppercase;
    padding: 10px 18px;
  }

  &_cost {
    @include media-sm {
      margin-top: 30px;
    }
  }

  &_items {
    margin-top: 10px;

    @include media-sm {
      margin-top: 41px;
    }

    &_title {
      @include subtitle3($c-white);
      margin-bottom: 12px;

      @include media-sm {
        margin-bottom: 30px;
      }
    }
  }
}
