@import '../../../../styles/mixins.scss';

.form {
  &_input {
    margin-top: 30px;

    .input {
      width: 100%;
      height: 60px;
    }

    &_name {
      @include subtitle($c-white, 'bold');
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_wrap {
      text-align: center;
    }
  }
}
