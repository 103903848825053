@import '../../styles/mixins.scss';

.container {
  @include container();
  z-index: 2;
  position: relative;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.content {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;

  @include media-md {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
}

.logo {
  width: 350px;
  padding-top: 35px;
  display: block;

  img {
    width: 100%;
  }
}

.wrap {
  min-height: 1000px;
  height: 100%;
}

.planet {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include media-md {
    display: none;
  }

  img {
    width: 175px;
    height: 175px;
  }

  &_right {
    position: absolute;
    top: -50px;
    left: -200px;
    width: 180px;
    height: 180px;

    @include media-md {
      left: -80px;
      width: 150px;
      height: 150px;
      top: -80px;
    }

    img {
      width: 100%;
    }
  }
}

.left {
  margin-left: -100px;
  margin-top: 100px;
  margin-bottom: 55px;
  width: 100%;

  @include media-md {
    margin-left: 0;
    margin-top: 0;
  }

  img {
    @include media-lg {
      width: 100%;
    }

    @include media-md {
      width: 50%;
      margin: auto;
      display: block;
    }
  }
}

.right {
  position: relative;
}
