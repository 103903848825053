@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  border: 1px solid $c-white;
  border-radius: 10px;
  padding: 15px;

  &_top {
    display: flex;
    justify-content: space-between;
    color: $c-white;

    @include media-xs {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  &_progress {
    margin-top: 15px;
    height: 15px;
    border: 1px solid $c-grey-300;
    border-radius: 10px;
  }

  &_name {
    @include additional2($c-white);
  }

  &_info {
    @include additional2($c-pink);
  }
}
