@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.description {
  line-height: 24px;
  margin-bottom: 49px;
  color: $c-white;

  &.two {
    margin-bottom: 55px;
    margin-top: 60px;

    @include media-sm {
      margin-top: 35px;
    }
  }
}

.h2 {
  margin-bottom: 27px;

  @include media-sm {
    margin-bottom: 22px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 330px;
  grid-gap: 40px;
  margin-top: 110px;

  @include media-lg {
    grid-template-columns: 1fr 30%;
  }

  @include media-md {
    grid-template-columns: 1fr;
  }

  @include media-sm {
    margin-top: 20px;
  }
}

.phone {
  text-align: center;
  position: sticky;
  top: 20px;

  @include media-md {
    position: static;
    width: 50%;
    margin: auto;
  }

  @include media-xs {
    width: 100%;
  }

  img {
    width: 80%;
  }

  &_description {
    color: #8289ac;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.info {
  &_subtitle {
    @include subtitle3($c-white);
    margin-bottom: 25px;
  }

  &_items {
    display: flex;

    @include media-lg {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 12px;
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
    }
  }

  &_item {
    margin-left: 12px;
    width: 155px;

    @include media-lg {
      margin-left: 0;
    }

    @include media-xs {
      width: 100%;
    }

    &:first-child {
      margin-left: 0;
    }

    &_img {
      height: 155px;

      @include media-sm {
        height: 138px;
      }
    }
  }
}

.collections {
  margin-top: 80px;

  @include media-sm {
    margin-top: 56px;
  }
}

.collection {
  background: #070d44;
  border-radius: 25px;
  text-align: center;
  padding: 55px 40px;

  @include media-sm {
    padding: 55px 33px;
  }

  &_items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 19px;

    @include media-sm {
      grid-template-columns: 1fr;
    }
  }

  &_title {
    @include subtitle3($c-white);
    margin-bottom: 35px;
  }

  &_img {
    margin-bottom: 44px;
    padding: 0 100px;

    img {
      width: 100%;
    }
  }

  &_info {
    padding: 24px 30px;
    background: #070d44;
    border-radius: 25px;
    margin-top: 20px;
  }

  &_text {
    color: $c-white;
  }
}

.up_btn {
  margin-top: 60px;
  width: 150px;

  @include media-sm {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;
  }
}

.status {
  margin-top: 60px;

  &_text {
    color: $c-white;
    line-height: 24px;
    margin-bottom: 25px;

    @include media-sm {
      margin-bottom: 40px;
    }
  }

  &_items {
    display: flex;
    justify-content: space-between;

    @include media-lg {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &_item {
    margin-left: 5px;

    @include media-lg {
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.principles {
  background: linear-gradient(
    103.42deg,
    #3323b8 -8.53%,
    #5a20ae 45.17%,
    #7a1da6 111.01%
  );
  border-radius: 25px;
  padding: 24px 30px;
  margin-top: 60px;

  &_title {
    @include subtitle3($c-white);
    margin-bottom: 25px;
  }

  &_text {
    color: $c-white;
    line-height: 24px;
    margin-bottom: 5px;

    @include media-sm {
      margin-bottom: 18px;
    }
  }

  &_items {
    display: flex;
    flex-wrap: wrap;
  }

  &_item {
    background: #070d44;
    border: 1px solid $c-white;
    border-radius: 10px;
    text-align: center;
    color: $c-white;
    padding: 17px 20px;
    margin-right: 11px;
    margin-top: 20px;

    @include media-sm {
      margin-top: 11px;
      width: 100%;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
