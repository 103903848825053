@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.content {
  width: 500px;
  margin-top: 80px;

  @include media-sm {
    width: 100%;
    margin-top: 53px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 200px;
  height: 200px;
  border: 3px solid $c-white;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media-sm {
    width: 100px;
    height: 100px;
    margin-bottom: 27px;
  }
}

.title {
  text-align: center;
  margin-bottom: 42px;

  @include media-sm {
    margin-bottom: 30px;
  }
}
