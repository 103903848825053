@import '../../../styles/mixins.scss';

.select {
  height: 100%;
  position: relative;

  &_field {
    background: rgba(38, 31, 138, 0.5);
    border-radius: 10px;
    color: white;
  }

  &_error {
    &_text {
      text-align: right;
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }

  &_disabled {
    opacity: 0.5;
  }

  &_option {
    width: 100%;
    display: flex !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border-bottom: 1px solid $c-grey-300;
    padding: 15px !important;
    align-items: center !important;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: red;
    }

    &_description {
      margin-top: 10px;
      font-size: 13px;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }

    &_inner {
      text-align: left;
    }
  }

  &_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
  }
}
