@import '../../../styles/mixins.scss';

.radio {
  display: flex;
  align-items: center;

  &_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_input {
    width: 24px;
    height: 24px;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 100%;
    border: 2px solid $c-white;

    &_circle {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }

  &_input:checked {
    border: 2px solid $c-pink;

    & + .radio_input_circle {
      background-color: $c-pink;

      display: block;
    }
  }

  &_label {
    user-select: none;
    cursor: pointer;
    width: 80%;
    padding-left: 17px;
  }
}
