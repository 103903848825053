@import '../../../styles/mixins.scss';

.collapse {
  background-color: $c-pink;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border: 2px solid rgba(53, 103, 242, 0.2);
    padding: 10px 30px;
    transition: $transitionDefaultDuration all ease;
    cursor: pointer;
    border-radius: 5px;

    &.active {
      border: 2px solid $c-pink;
    }
  }

  &_name {
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      height: 20px;
    }
  }

  &_arrow {
    transition: $transitionDefaultDuration all ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &_content {
    padding: 20px 30px;
    border: 2px solid rgba(53, 103, 242, 0.2);
    border-top: none;
    border-radius: 5px;
  }
}
