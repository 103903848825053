@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.content {
  margin-top: 64px;
}

.h2 {
  margin-bottom: 27px;
  text-align: center;
  margin-top: 34px;
}

.description {
  margin-bottom: 76px;
  text-align: center;
  color: $c-white;
  line-height: 24px;

  @include media-sm {
    margin-bottom: 53px;
  }
}

.tabs {
  &_tablist {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-xl {
      overflow-x: auto;
    }

    @include media-sm {
      margin-bottom: 40px;
    }

    @include media-xs {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &_tab {
    outline: none;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.form {
  margin-bottom: 60px;

  &_input {
    height: 60px;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 40px;
  grid-column-gap: 70px;
  margin-bottom: 60px;

  @include media-md {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 40px;
  }

  @include media-xs {
    grid-template-columns: 1fr;
  }
}

.btn {
  text-align: center;
}
