@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 23px 20px;
  background: rgba(38, 31, 138, 0.5);
  backdrop-filter: blur(64px);
  border-radius: 25px;
  height: 90px;

  @include media-xs {
    flex-direction: column;
    height: auto;
  }

  &_img {
    width: 40px;
    object-fit: cover;
    margin-left: 10px;

    @include media-xs {
      margin-top: 20px;
      margin-bottom: 12px;
      margin-left: 0;
    }
  }

  &_left {
    display: flex;
    align-items: center;

    @include media-xs {
      flex-direction: column;
    }
  }

  &_name {
    @include additional2($c-white);
    margin-left: 10px;

    @include media-xs {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  &_btn {
    width: 132px;
    padding-left: 0;
    padding-right: 0;

    @include media-xs {
      height: 50px;
    }
  }

  &_edit {
    border-radius: 7px;
    background-color: $c-dark;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include media-xs {
      width: 132px;
      height: 50px;
    }
  }
}
