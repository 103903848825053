@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    100.69deg,
    #241e87 8.56%,
    rgba(36, 30, 135, 0) 111.52%
  );
  backdrop-filter: blur(64px);
  border-radius: 25px;

  &_text {
    color: $c-white;
  }

  &_name {
    color: $c-grey-50;
  }

  &_right {
    text-align: right;
  }

  &_img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    object-fit: cover;
  }

  &_price {
    @include additional($c-aqua);
    text-transform: uppercase;
  }

  &_status {
    font-family: 'Gilroy-Bold';
    text-transform: uppercase;
    color: $c-white;
    margin-top: 6px;
  }

  &_subtitle {
    @include additional2($c-aqua, 'bold');
    margin-bottom: 6px;
  }

  &_left {
    display: flex;
    align-items: center;
  }

  &_info {
    margin-left: 16px;
  }
}
