@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  background: rgba(38, 31, 138, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(64px);
  border-radius: 25px;
  padding: 21px 20px;
  width: 100%;

  @include media-sm {
    padding: 15px;
    border-radius: 20px;
  }

  &.active {
    background: $c-pink;
  }

  &_img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 100%;
    overflow: hidden;

    @include media-sm {
      margin-right: 10px;
      width: 36px;
      height: 36px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_text {
    @include additional($c-white, 'bold');
    white-space: nowrap;
  }
}
