@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  background: rgba(16, 24, 109, 0.7);
  padding: 30px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &_img {
    img {
      width: 100%;
      border-radius: 50px;
    }
  }

  &_level {
    margin-top: 28px;
    background: #07114c;
    border: 2px solid $c-blue;
    border-radius: 20px;
    padding: 16px 40px;
    @include additional2($c-white, 'bold', 16px);

    @include media-sm {
      margin-top: 20px;
    }
  }

  @include media-sm {
    padding: 20px;
    border-radius: 30px;
  }
}
