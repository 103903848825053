@import '../../styles/mixins.scss';

.footer {
  padding-top: 166px;
  width: 900px;
  margin: auto;
  position: relative;

  @include media-md {
    width: 100%;
  }

  @include media-sm {
    padding-top: 120px;
  }

  &_wrap {
    z-index: 10;
    position: relative;
  }

  &_logo {
    width: 600px;
    margin: auto;
    display: block;

    img {
      width: 100%;
    }

    @include media-sm {
      width: 80%;
    }
  }

  &_menu {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    grid-gap: 60px;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 81px;

    &_item {
      color: $c-white;
      margin-bottom: 20px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-sm {
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
    }

    @include media-xs {
      grid-gap: 10px;
    }
  }

  &_join {
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    text-align: center;
    padding-bottom: 68px;
    grid-gap: 20px;

    &_us {
      padding-top: 10px;
      border-right: 2px solid #231955;
      padding-right: 43px;
      padding-bottom: 10px;

      @include media-md {
        padding-right: 0;
        border-right: none;
        padding-bottom: 68px;
        border-bottom: 2px solid #231955;
        margin-bottom: 60px;
      }
    }

    @include media-md {
      grid-template-columns: 1fr;
    }
  }

  &_subtitle {
    @include additional($c-white);
    margin-bottom: 7px;
  }

  &_description {
    @include additional2($c-grey-300);
  }

  &_form {
    display: flex;
    margin-top: 24px;

    @include media-xs {
      flex-direction: column;
      align-items: center;
    }

    &_btn {
      @include media-xs {
        margin-top: 20px;
      }
    }
  }

  &_input {
    margin-right: 15px;
    width: 100%;

    &_wrap {
      text-align: center;
    }

    @include media-xs {
      margin-right: 0;
    }
  }

  &_socials {
    &_items {
      margin-top: 36px;
      display: flex;

      @include media-md {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &_item {
      margin-left: 15px;
      height: 27px;
      width: 27px;
      margin: 14px 7px 0;

      @include media-md {
        margin: 12px 6px 0;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &_bscscan {
    display: flex;
    justify-content: center;
  }

  &_links {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 27px;

    &_item {
      padding: 0 15px;
      color: $c-white;

      @include media-xs {
        padding-bottom: 40px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    @include media-xs {
      display: flex;
      flex-direction: column;
    }
  }

  &_allrights {
    @include additional2($c-grey-300);
    text-align: center;
    padding-bottom: 102px;

    @include media-xs {
      padding-bottom: 49px;
      padding-top: 20px;
    }
  }

  &_bg {
    position: absolute;
    z-index: 0;
    left: calc(50% - 437px);
    bottom: -568px;
    width: 875px;
    height: 875px;
    background: rgba(215, 21, 255, 0.2);
    -webkit-filter: blur(250px);
    filter: blur(250px);
  }
}
