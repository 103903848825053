@import '../../../../styles/mixins.scss';

.datepicker {
  position: relative;

  &_input {
    width: 100%;
    height: 53px;
  }
}

.input {
  margin-bottom: 30px;

  &_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &_name {
    color: $c-white;
  }
}
