@import '../../styles/mixins.scss';

.header {
  &_connect {
    display: flex;
    align-items: center;
    gap: 26px;
    justify-content: flex-end;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 9999;

    @include media-md {
      top: 14px;

      a img {
        height: 20px;

        @include media-sm {
          display: none;
        }
      }
    }

    &_btn {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        height: 17px;

        @include media-md {
          display: none;
        }
      }
    }

    button {
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;

      @include media-md {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
      }
    }
  }
}
