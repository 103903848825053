@import '../../../styles/mixins.scss';

.button {
  background: $c-pink;
  box-shadow: 0px 5px 0px #77188c;
  border-radius: 10px;
  font-family: 'Gilroy-Medium';
  text-align: center;
  text-transform: capitalize;
  color: $c-white;
  padding: 15px 35px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  transition: $transitionDefaultDuration all ease;
  font-weight: 700;

  &:hover {
    background: #780c8e;
    box-shadow: none;
    transform: translateY(4px);
  }

  &._blue {
    background: $c-blue;
    box-shadow: 0px 5px 0px #1d3a8a;

    &:hover {
      background: #0a42ea;
      box-shadow: none;
      transform: translateY(4px);
    }
  }

  &._dark {
    background: $c-dark;
    box-shadow: none;
  }

  &.disabled {
    opacity: 0.7;
  }
}
