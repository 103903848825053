@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  background: rgba(38, 31, 138, 0.5);
  border-radius: 30px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
  cursor: pointer;

  &.active {
    border: 2px solid $c-blue;
  }

  &_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 30px;
    pointer-events: none;
    transition: 1s;
  }

  &:hover .item_img {
    transform: scale(1.2);
  }

  &_name {
    @include subtitle($c-white, 'bold', 26px);
    margin-top: 24px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &_category {
    background: $c-blue;
    border-radius: 0px 10px 10px 0px;
    color: $c-white;
    font-family: 'Gilroy-Bold';
    padding: 12px 21px;
    position: absolute;
    top: 44px;
    left: 20px;
  }

  &_wrapper {
    overflow: hidden;
    border-radius: 30px;
  }

  &.small {
    padding: 9px 9px 19px 9px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

    .item_img {
      border-radius: 15px;
      height: 150px !important;
    }

    .item_name {
      @include additional2($c-white, 'bold', 15px);
      margin-top: 10px;
    }

    .item_category {
      border-radius: 0px 5px 5px 0px;
      @include additional3($c-white, 'bold', 12px);
      padding: 7px 12px;
      top: 20px;
      left: 9px;
    }
  }
}
