@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.content {
  width: 500px;
  margin-top: 80px;

  @include media-sm {
    width: 100%;
    margin-top: 53px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 200px;
  height: 200px;
  border: 3px solid $c-white;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  z-index: 2;

  @include media-sm {
    width: 100px;
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_left {
    position: absolute;
    left: 110px;
    opacity: 0.4;
    z-index: 1;

    @include media-sm {
      left: 100px;
    }
  }

  &_right {
    position: absolute;
    right: 110px;
    opacity: 0.4;
    z-index: 1;

    @include media-sm {
      right: 100px;
    }
  }

  &_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include media-sm {
      width: 260px;
      margin: auto;
    }
  }
}

.title {
  text-align: center;
  margin-bottom: 42px;

  @include media-sm {
    margin-bottom: 30px;
  }
}

.tabs {
  display: flex;
  margin-bottom: 40px;
  background: linear-gradient(
    92.07deg,
    #05062c -98.82%,
    #0d1361 39.77%,
    #0b1265 162.76%
  );
  border-radius: 30px;

  &_link {
    width: 100%;
  }

  @include media-xs {
    flex-direction: column;
  }
}
