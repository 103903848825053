@import './variables.scss';

// Media
@mixin media-xxs {
  @media (max-width: $breakpointXXs) {
    @content;
  }
}

@mixin media-xs {
  @media (max-width: $breakpointXs) {
    @content;
  }
}

@mixin media-sm {
  @media (max-width: $breakpointSm) {
    @content;
  }
}

@mixin media-md {
  @media (max-width: $breakpointMd) {
    @content;
  }
}

@mixin media-lg {
  @media (max-width: $breakpointLg) {
    @content;
  }
}

@mixin media-xl {
  @media (max-width: $breakpointXl) {
    @content;
  }
}

@mixin addFont($font: 'Gilroy', $style: 'Bold') {
  $font: to-lower-case($font);
  $style: to-lower-case($style);

  @if $font == 'gilroy' {
    @if $style == 'bold' {
      font-family: 'Gilroy-Bold';
    }
    @if $style == 'medium' {
      font-family: 'Gilroy-Medium';
    }
    @if $style == 'semibold' {
      font-family: 'Gilroy-SemiBold';
    }
  }

  @if $font == 'gemina' {
    font-family: 'Gemina';
  }
}

// Typography
@mixin h1($color: $c-black) {
  @include addFont($font: 'Gemina');
  font-size: 60px;
  line-height: 78px;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-md {
    font-size: 50px;
  }
  @include media-xs {
    font-size: 30px;
    line-height: 33px;
  }
}

@mixin h2($color: $c-black) {
  @include addFont($font: 'Gemina');
  font-size: 45px;
  line-height: 58px;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-md {
    font-size: 40px;
  }
  @include media-xs {
    font-size: 30px;
    line-height: 40px;
  }
}

@mixin h3($color: $c-black) {
  @include addFont($font: 'Gemina');
  font-size: 30px;
  line-height: 39px;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-md {
    font-size: 25px;
  }
  @include media-xs {
    font-size: 23px;
    line-height: 30px;
  }
}

@mixin title($color: $c-black, $style: 'medium', $liheHeight: 46px) {
  @include addFont($font: 'Gilroy', $style: $style);
  font-size: 36px;
  line-height: $liheHeight;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 26px;
  }
}

@mixin subtitle($color: $c-black, $style: 'medium', $liheHeight: 30px) {
  @include addFont($font: 'Gilroy', $style: $style);
  font-size: 20px;
  line-height: $liheHeight;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 18px;
  }
}

@mixin additional($color: $c-black, $style: 'medium', $liheHeight: 21px) {
  @include addFont($font: 'Gilroy', $style: $style);
  font-size: 18px;
  line-height: $liheHeight;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 16px;
  }
}

@mixin additional2($color: $c-black, $style: 'medium', $liheHeight: 16px) {
  @include addFont($font: 'Gilroy', $style: $style);
  font-size: 14px;
  line-height: $liheHeight;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 12px;
  }
}

@mixin additional3($color: $c-black, $style: 'medium', $liheHeight: 16px) {
  @include addFont($font: 'Gilroy', $style: $style);
  font-size: 10px;
  line-height: $liheHeight;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 9px;
  }
}

@mixin subtitle2($color: $c-black) {
  @include addFont($font: 'Gemina');
  font-size: 24px;
  line-height: 31px;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 20px;
  }
}

@mixin subtitle3($color: $c-black) {
  @include addFont($font: 'Gemina');
  font-size: 18px;
  line-height: 23px;
  color: $color;
  transition: $transitionDefaultDuration all ease;

  @include media-xs {
    font-size: 15px;
  }
}

// Other
@mixin container {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  min-width: 320px;
  max-width: 1300px;
  margin: auto;

  @include media-xl {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include media-md {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media-xs {
    padding-left: 15px;
    padding-right: 15px;
  }
}
