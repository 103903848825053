@import '../../../../styles/mixins.scss';

.item {
  width: 100%;
  background: rgba(38, 31, 138, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_wrap {
    display: flex;
    width: 80%;
  }

  &_icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  &_title {
    @include subtitle($c-white, 'bold', 26px);
    margin-bottom: 10px;
    text-align: left;
  }

  &_description {
    @include additional2($c-white);
    text-align: left;
  }

  &_btn {
    height: 40px;
    width: 40px;
    padding: 0;
  }

  &_radio {
    width: 40px;
    justify-content: flex-end;
  }

  &_input {
    margin-top: 20px;
    width: 100%;
    height: 100px;
  }
}
