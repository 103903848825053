@import '../../styles/mixins.scss';

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 13px;

  @include media-md {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-xs {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.btns {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 13px;
  justify-content: flex-end;

  @include media-sm {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.tabs {
  &_tablist {
    margin-bottom: 60px;

    @include media-sm {
      margin-bottom: 40px;
    }

    &_wrap {
      display: flex;
      justify-content: center;

      @include media-sm {
        overflow-x: auto;
        justify-content: flex-start;
        margin-right: -20px;
        padding-right: 20px;
      }

      @include media-xs {
        margin-right: -15px;
        padding-right: 15px;
      }
    }
  }

  &_titles {
    @include media-md {
      flex-direction: column;
    }
  }

  &_form {
    height: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;

    @include media-sm {
      flex-direction: column;
      height: auto;
    }
  }

  &_input {
    height: 100%;
  }

  &_tabpanel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_items {
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 13px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 0;
    }

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &_btn {
    margin-top: 61px;

    @include media-sm {
      margin-top: 40px;
    }

    &_wrap {
      display: flex;
      justify-content: center;
    }
  }
}

.cancel {
  display: flex;
  align-items: center;

  @include media-sm {
    width: 100%;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  &_remove {
    margin-right: 13px;

    @include media-sm {
      margin-right: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &_text {
    @include additional2($c-grey-100);
    margin-right: 20px;

    @include media-sm {
      width: 100%;
      margin-right: 0;
    }
  }
}

.select {
  width: 200px;
  margin-right: 10px;

  @include media-sm {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    margin-top: 10px;
  }
}

.input {
  &:first-child {
    width: 60%;

    @include media-sm {
      width: 100%;
    }
  }

  &:last-child {
    width: 38%;

    @include media-sm {
      margin-top: 10px;
      width: 100%;
    }
  }
}
