@import '../../../../styles/mixins.scss';

.logo {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px auto;
  padding: 20px;

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0;

    & > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 10px;
    }

    button {
      width: 157px;
      margin-top: 20px;
    }
  }

  @include media-sm {
    padding: 30px 50px;
  }

  &_icon {
    margin-bottom: 10px;
    width: 30px;

    img {
      width: 100%;
    }
  }

  &_title {
    @include subtitle($c-white);
    margin-bottom: 9px;
  }

  &_description {
    @include additional2($c-grey-300);
    margin-bottom: 25px;
  }

  &_close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: rgb(204, 204, 204);
    width: 25px;
  }
}

.hide {
  display: none;
}

.red {
  color: red;
}
