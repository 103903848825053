@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.content {
  width: 530px;
  margin-top: 80px;

  @include media-sm {
    width: 100%;
    margin-top: 53px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 200px;
  height: 200px;
  border: 3px solid $c-white;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  z-index: 2;

  @include media-sm {
    width: 100px;
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_left {
    position: absolute;
    left: 110px;
    opacity: 0.4;
    z-index: 1;

    @include media-sm {
      left: 100px;
    }
  }

  &_right {
    position: absolute;
    right: 110px;
    opacity: 0.4;
    z-index: 1;

    @include media-sm {
      right: 100px;
    }
  }

  &_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include media-sm {
      width: 260px;
      margin: auto;
    }
  }
}

.title {
  text-align: center;
  margin-bottom: 42px;

  @include media-sm {
    margin-bottom: 35px;
  }
}

.tabs {
  &_tablist {
    margin-bottom: 40px;

    @include media-sm {
      margin-bottom: 60px;
    }
  }

  &_titles {
    @include media-sm {
      flex-direction: column;
    }
  }
}

.form {
  text-align: center;

  &_search {
    &_input {
      height: 60px;
    }
  }

  &_input {
    margin-bottom: 30px;

    .input {
      width: 100%;
      height: 60px;
    }

    &_name {
      @include subtitle($c-white, 'bold');
      margin-bottom: 20px;
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_wrap {
      text-align: center;
    }

    &_icon {
      cursor: pointer;
      width: 36px;
      height: 36px;
      background-color: $c-dark;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_textarea {
    margin-bottom: 30px;
    position: relative;

    .textarea {
      height: 250px;
    }

    &_up {
      position: absolute;
      bottom: 30px;
      right: 30px;
      @include additional2($c-white);
      opacity: 0.6;
    }
  }
}

.items {
  background: $c-dark;
  backdrop-filter: blur(20px);
  padding: 20px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
  height: 440px;
  overflow-y: scroll;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  &_item {
    height: 190px;
  }
}
