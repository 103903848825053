@import '../../../styles/mixins.scss';

.popup {
  &_btn {
    width: 100%;
    margin-top: 50px;
  }
}

.property {
  &_titles {
    display: flex;
    margin-bottom: 10px;
  }

  &_title {
    @include additional($c-white);
    padding: 5px;
    width: 100%;
    padding-left: 23px;

    &:first-child {
      padding-left: 50px;
    }
  }

  &_item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_add {
    margin-top: 10px;
  }
}
