@import '../../../../styles/mixins.scss';

.back {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);

  img {
    width: 100%;
    height: 100%;
  }
}

.btn {
  width: 100%;
  margin-top: 50px;
}

.title {
  @include subtitle($c-white);
  text-align: center;
  margin-bottom: 20px;

  span {
    color: $c-blue;
  }
}

.description {
  @include additional2($c-white);
  margin-bottom: 30px;
  text-align: center;
}
