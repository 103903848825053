@import '../../styles/mixins.scss';

.property {
  display: flex;

  &_close {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 25px;

    img {
      width: 25px;
    }

    @include media-xs {
      margin-right: 5px;
    }
  }

  &_input {
    width: 100%;

    &:last-child {
      margin-left: 10px;
    }
  }
}
