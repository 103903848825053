@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  cursor: pointer;

  &_skeleton {
    display: block;
    height: 100%;

    &.active {
      display: none;
    }
  }

  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
    transition: 1s;

    &.active {
      display: block;
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  span {
    height: 100%;
    width: 100%;
  }
}
