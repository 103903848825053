@import '../../../styles/mixins.scss';

button.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a0c43;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  transition: $transitionDefaultDuration all ease;
  z-index: 2;

  img {
    width: 8px;
    transform: rotate(180deg);
    z-index: 1;
  }

  &:hover {
    background-color: #0a0c43;
  }

  &:before {
    content: '';
  }
}
