@import '../../../styles/mixins.scss';

.table {
  padding: 30px;
  background-color: $c-dark;
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding-bottom: 0;
  overflow-x: auto;

  &_rows {
    @include additional($c-white);
  }
}
