@import '../../../../styles/mixins.scss';

.creator {
  display: flex;
  justify-content: flex-start;

  &_inputs {
    width: 100%;
  }

  &_input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      height: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_add {
    margin-top: 20px;
  }
}

.input {
  width: 50%;

  &:first-child {
    width: 100%;
    margin-right: 10px;
  }
}
