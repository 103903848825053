@import '../../styles/mixins.scss';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  transition: $transitionDefaultDuration all ease;
  z-index: 9999;
  padding: 0 22px;
  display: none;
  height: 72px;
  background: #110847;
  align-items: center;
  opacity: 0.9;

  &_backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }

  @include media-md {
    display: flex;
  }

  &_logo {
    width: 200px;
    margin-left: 30px;
    display: flex;

    img {
      width: 30px;
    }
  }

  &.active {
    opacity: 1;
  }

  &_burger {
    cursor: pointer;
    background: none;
    display: none;
    width: 19px;
    height: 42px;
    position: relative;
    z-index: 9999;

    @include media-md {
      display: block;
    }

    &.active {
      i {
        background-color: transparent;

        &:before {
          transform: translateY(6px) rotate(45deg);
        }

        &:after {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }

    i {
      display: block;
      background-color: $c-white;
      height: 3px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: $transitionDefaultDuration;

      &:before {
        content: '';
        display: block;
        background-color: $c-white;
        height: 3px;
        width: 100%;
        position: absolute;
        left: 0;
        transition: $transitionDefaultDuration;
        top: -7px;
      }

      &:after {
        content: '';
        display: block;
        background-color: $c-white;
        height: 3px;
        width: 100%;
        position: absolute;
        left: 0;
        transition: $transitionDefaultDuration;
        bottom: -7px;
      }
    }
  }

  &_profile {
    width: 50px;
    height: 50px;
    border: 2px solid $c-pink;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_connect {
    display: flex;
    align-items: center;
    gap: 26px;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;

    &_btn {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        height: 17px;
      }
    }

    button {
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
