@import '../../../styles/mixins.scss';

.checkbox {
  &_label {
    color: $c-white;

    &_wrap {
      margin-left: 16px;
    }
  }

  &_input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }

    & + label::before {
      content: '';
      display: inline-block;
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 15px 15px;
      background-color: rgba(7, 3, 58, 0.5);
      cursor: pointer;
    }

    &:checked + label::before {
      border-color: #0b76ef;
      background-color: #0b76ef;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &:not(:disabled):not(:checked) + label:hover::before {
      border-color: #b3d7ff;
    }
    /* стили для активного состояния чекбокса (при нажатии на него) */
    &:not(:disabled):active + label::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
    }
    /* стили для чекбокса, находящегося в фокусе */
    &:focus + label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    &:focus:not(:checked) + label::before {
      border-color: #80bdff;
    }
    /* стили для чекбокса, находящегося в состоянии disabled */
    &:disabled + label::before {
      background-color: #e9ecef;
    }
  }
}
