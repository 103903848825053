@import '../../../styles/mixins.scss';

button.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a0c43;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  transition: $transitionDefaultDuration all ease;

  img {
    width: 8px;
  }

  &:hover {
    background-color: #0a0c43;
  }

  &:before {
    content: '';
  }
}
