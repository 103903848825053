@import '../../styles/mixins.scss';

.table {
  &_tabs {
    margin-top: 151px;

    @include media-sm {
      margin-top: 80px;
    }

    &_tablist {
      margin-bottom: 27px;
    }
  }

  &_row {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    justify-content: space-between;
    padding: 21px 5px;
    border-bottom: 1px solid #353d6d;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    @include media-md {
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &_titles {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    @include subtitle3($c-white);
    font-size: 16px;
    text-shadow: none;
    padding-bottom: 30px;
    border-bottom: 1px solid #353d6d;
    justify-content: space-between;
    white-space: nowrap;

    @include media-md {
      width: 700px;
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
      font-size: 12px;
    }
  }

  &_rows {
    @include media-md {
      width: 700px;
      font-size: 12px;
    }
  }

  &_img {
    width: 32px;
    height: 30px;
    object-fit: cover;
    margin-right: 12px;
    border-radius: 100%;
  }
}
