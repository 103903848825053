@import '../../styles/mixins.scss';

.prevpage {
  display: inline-block;

  &_wrap {
    display: flex;
    align-items: center;
  }

  &_text {
    @include additional($c-white, 'medium', 21px);
    margin-left: 10px;
  }

  &_icon {
    transform: rotate(90deg);
  }

  &_image {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
