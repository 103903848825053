@import '../../../styles/mixins.scss';

.input {
  position: relative;

  &_wrap {
    position: relative;
    height: 100%;

    &.prefix {
      display: flex;
      align-items: center;
      background: rgba(38, 31, 138, 0.5);
      overflow: hidden;
      border-radius: 10px;

      input {
        text-align: left;
      }
    }
  }

  &_prefix {
    padding-left: 15px;
    color: $c-grey-300;
    font-size: 13px;
    white-space: nowrap;
    text-align: left;

    &_left_icon {
      padding-left: 50px;
    }
  }

  // &_right_icon {
  //   padding-right: 40px !important;
  // }

  &_left_icon {
    padding-left: 44px !important;
  }

  .right_icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  .left_icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
  }

  &_field {
    background: rgba(38, 31, 138, 0.5);
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    outline: none;
    padding: 15px;
    resize: none;
    color: $c-white;
    font-family: 'Gilroy-Medium';
    border: 2px solid transparent;

    &::placeholder {
      color: $c-grey-300;
    }

    &_prefix {
      background: transparent;
      padding-left: 0 !important;
      border-radius: 0;
    }
  }

  &_label {
    margin-bottom: 15px;
    display: block;
    user-select: none;
    @include subtitle($c-white, 'bold');
  }

  &_error {
    border: 2px solid $c-pink;

    &_text {
      font-size: 14px;
      color: $c-pink;
      text-align: right;
      position: absolute;
      bottom: -25px;
      right: 0;
      display: flex;
      align-items: center;
    }
  }
}
