@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background: rgba(16, 24, 109, 0.7);
  border-radius: 25px;
  padding: 22px 37px;
  cursor: pointer;
  white-space: nowrap;

  @include media-sm {
    padding: 12px 37px;
  }

  &_text {
    color: $c-white;
  }
}
