@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.wrapper {
  position: relative;
}

.main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 71px;
  margin-top: 40px;

  @include media-md {
    grid-gap: 15px;
  }

  @include media-sm {
    grid-template-columns: 1fr;
  }

  &_img {
    padding: 23px;

    img {
      width: 100%;
    }

    @include media-sm {
      padding: 0;
      padding-bottom: 60px;
    }
  }

  &_items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;

    @include media-md {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &_item {
    img {
      height: 100px;
    }
  }
}

.main_items {
  .main_item div:first-child img {
    height: 100px !important;
  }
}

.slider {
  margin-top: 172px;

  @include media-sm {
    margin-top: 80px;
  }
}
