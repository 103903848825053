@import '../../styles/mixins.scss';

.loading {
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background: rgba(1, 1, 1, 1);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &_progress {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 1%;
    height: 15px;
    background: linear-gradient(90deg, #2824f5 0.29%, #c02ff6 94.19%);
    transition: width 1s ease-in;
  }

  &_body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    img {
      pointer-events: none;
      user-select: none;
      width: 460px;
      transform: perspective(750px) translate3d(0, 0, -250px) rotateX(27deg)
        scale(0.9, 0.9);
      animation: mooving 5s ease-in-out infinite;
    }
  }
}

@keyframes mooving {
  0% {
    transform: perspective(750px) translate3d(0, 0, -250px) rotateX(27deg)
      scale(0.9, 0.9);
  }

  50% {
    transform: translate3d(0, 0, -250px);
  }

  100% {
    transform: perspective(750px) translate3d(0, 0, -250px) rotateX(27deg)
      scale(0.9, 0.9);
  }
}

.showEnter {
  opacity: 0;
}

.showEnterActive {
  opacity: 1;
  transition: opacity $transitionDefaultDuration;
}

.showExit {
  opacity: 1;
}

.showExitActive {
  opacity: 0;
  transition: opacity $transitionDefaultDuration;
}
