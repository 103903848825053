// Color Variables
$c-white: #fff;
$c-black: #000;
$c-dark: #07033a;
$c-pink: #d62efc;
$c-grey-50: #f5f5f5;
$c-grey-100: #dddee1;
$c-grey-300: #868196;
$c-aqua: #00f1ff;
$c-blue: #3567f2;

// Transitions Variables
$transitionDefaultDuration: 0.3s;

// Breakpoints
$breakpointXl: 1399px;
$breakpointLg: 1199px;
$breakpointMd: 991px;
$breakpointSm: 768px;
$breakpointXs: 475px;
$breakpointXXs: 375px;
