@import '../../styles/mixins.scss';

.h1 {
  margin-bottom: 67px;

  @include media-sm {
    margin-bottom: 34px;
  }
}

.description {
  @include subtitle($c-white);
  margin-bottom: 61px;
}

.section {
  &_top {
    text-align: center;
    margin-top: 159px;

    @include media-md {
      margin-top: 50px;
    }

    &_bg {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: -1;
      left: 0;
      right: 0;
    }
  }
}

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.footer {
  background: $c-dark;
}

.tabs {
  &_tablist {
    margin-bottom: 39px;

    &_wrap {
      display: flex;
      justify-content: center;
      margin-right: -20px;
      padding-right: 20px;

      @include media-md {
        overflow-x: auto;
        justify-content: flex-start;
      }

      @include media-xs {
        margin-right: -15px;
        padding-right: 15px;
      }
    }

    &_items {
      @include media-sm {
        flex-direction: column;
      }
    }
  }

  &_form {
    display: flex;
    justify-content: center;
    margin-top: 39px;

    @include media-sm {
      flex-direction: column;
    }
  }

  &_tabpanel {
    margin-top: 151px;

    @include media-sm {
      margin-top: 57px;
    }
  }

  &_items {
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 13px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &_category {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_input {
    width: 600px;

    @include media-sm {
      width: 100%;
    }
  }

  &_dropdown {
    display: flex;
    align-items: center;
    margin-left: 60px;

    &_label {
      @include additional($c-white, 'bold', 21px);
      margin-right: 18px;
      white-space: nowrap;
    }

    @include media-sm {
      margin-top: 20px;
      margin-left: 0;
      justify-content: space-between;
      height: 50px;
    }
  }

  .dropdown {
    background: rgba(16, 24, 109, 0.7);
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  &_nftitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 70px;
    margin-bottom: 60px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  &_independentitems {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 13px;
    margin-bottom: 60px;

    @include media-lg {
      grid-template-columns: repeat(5, 1fr);
    }

    @include media-md {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.wrapper {
  position: relative;
}
