@import '../../styles/mixins.scss';

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 40px;
  grid-column-gap: 70px;
  margin-bottom: 60px;

  @include media-md {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-xs {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}

.wrap {
  margin-top: 39px;
}
