@import '../../styles/mixins.scss';

.connect {
  margin-bottom: 40px;
  background: $c-dark;
  border-radius: 25px;
  padding: 32px 100px;
  text-align: center;

  @include media-xs {
    padding: 32px 50px;
  }

  &_title {
    color: #8289ac;
    margin-bottom: 38px;

    @include media-xs {
      margin-bottom: 30px;
    }
  }

  &_items {
    display: flex;
    justify-content: space-between;

    @include media-xs {
      flex-direction: column;
      grid-gap: 23px;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include media-xs {
      margin: auto;
    }

    &_icon {
      width: 26px;
      height: 26px;

      img {
        width: 100%;
      }
    }

    &_name {
      color: $c-white;
      margin-left: 13px;
    }
  }
}
