@import '../../styles/mixins.scss';

.tooltip {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  img {
    width: 17px;
  }
}
