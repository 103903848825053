@import '../../../styles/mixins.scss';

.item {
  background: $c-dark;
  border-radius: 25px;
  transition: $transitionDefaultDuration all ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 30px;

  @include media-xs {
    padding: 30px 50px;
    height: 100%;
  }

  &_name {
    color: $c-white;
  }

  &_icon {
    margin-bottom: 20px;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  &.active {
    background: $c-pink;

    .item_title:before {
      background-position: 0 100%;
    }
  }
}
