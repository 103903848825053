@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  background: rgba(38, 31, 138, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_wrap {
    display: flex;
  }

  &_icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  &_title {
    @include subtitle($c-white, 'bold', 26px);
    margin-bottom: 10px;
    text-align: left;
  }

  &_description {
    @include additional2($c-white);

    @include media-xs {
      width: 100%;
      text-align: left;
    }
  }

  &_btn {
    height: 40px;
    width: 40px;
    padding: 0;

    @include media-xs {
      width: 100%;
      margin-top: 20px;
    }
  }

  &_added {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 7px;
    margin-top: 20px;
    justify-content: space-between;

    &_progress {
      width: 100%;
      margin-top: 20px;

      &_item {
        width: 80% !important;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_item {
      color: $c-white;
      border: 1px solid $c-white;
      border-radius: 10px;
      padding: 10px;
      text-align: center;
    }

    &_type {
      @include additional3($c-pink);
      margin-bottom: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
