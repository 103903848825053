@import '../../../styles/mixins.scss';

.uploadimage {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 78px 90px;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  height: 358px;
  position: relative;

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    & > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 10px;
    }

    button {
      width: 157px;
      margin-top: 20px;
    }
  }

  @include media-sm {
    padding: 30px 50px;
  }

  &_icon {
    margin-bottom: 24px;
  }

  &_title {
    @include subtitle($c-white);
    margin-bottom: 9px;
  }

  &_description {
    @include additional2($c-grey-300);
    margin-bottom: 25px;
  }

  &_close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: rgb(204, 204, 204);
    width: 25px;
  }
}

.form {
  text-align: center;

  &_btn {
    margin-top: 50px;
  }

  &_input {
    margin-bottom: 30px;

    .input {
      width: 100%;
      height: 60px;
    }

    &_name {
      @include subtitle($c-white, 'bold');
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_wrap {
      text-align: center;
    }

    &_icon {
      cursor: pointer;
      width: 36px;
      height: 36px;
      background-color: $c-dark;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_textarea {
    margin-bottom: 30px;
    position: relative;

    .textarea {
      height: 250px;
    }

    &_up {
      position: absolute;
      bottom: 10px;
      right: 10px;
      @include additional2($c-white);
      background: rgba(38, 31, 138, 1);
      padding: 10px;
      border-radius: 10px;
      opacity: 0.6;
    }
  }

  &_collection {
    &_item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.hide {
  display: none;
}

.red {
  color: red;
}

.tooltip {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  img {
    width: 17px;
  }
}

.form {
  text-align: center;

  &_btns {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    @include media-xs {
      flex-direction: column;

      button {
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}
