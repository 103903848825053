@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.section_top {
  margin-top: 10px;
  position: relative;
  margin-bottom: 75px;

  &_bg {
    width: 100%;
    border-radius: 25px;
    height: 200px;
    object-fit: cover;

    @include media-sm {
      height: 100px;
    }
  }

  &_avatar {
    width: 150px;
    height: 150px;
    border: 3px solid $c-white;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translate(-50%, 0);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media-sm {
      width: 80px;
      height: 80px;
      bottom: -40px;
    }
  }
}

.name {
  @include subtitle2($c-white);
  text-align: center;
  padding-top: 40px;
  padding-bottom: 35px;

  @include media-sm {
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.tabs {
  &_tablist {
    margin-bottom: 60px;

    @include media-sm {
      margin-bottom: 40px;
    }

    &_wrap {
      display: flex;
      justify-content: center;

      @include media-md {
        overflow-x: auto;
        justify-content: flex-start;
        margin-right: -20px;
        padding-right: 20px;
      }

      @include media-xs {
        margin-right: -15px;
        padding-right: 15px;
      }
    }
  }

  &_titles {
    @include media-md {
      flex-direction: column;
    }
  }

  &_form {
    height: 60px;
    margin-bottom: 60px;
  }

  &_input {
    height: 100%;
  }

  &_tabpanel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_items {
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 13px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 0;
    }

    @include media-xs {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &_btn {
    margin-top: 61px;

    @include media-sm {
      margin-top: 40px;
    }

    &_wrap {
      display: flex;
      justify-content: center;
    }
  }
}

.wallets {
  margin-top: 145px;

  @include media-sm {
    margin-top: 81px;
  }

  &_title {
    margin-bottom: 44px;
    text-align: center;
  }

  &_items {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;

    @include media-sm {
      width: 100%;
    }
  }

  &_item {
    margin-bottom: 13px;

    &:first-child {
      div:first-child img {
        width: 150px;
      }
    }

    &_connect {
      display: flex;
      background-color: $c-dark;
      justify-content: space-between;
      align-items: center;
      padding: 23px 20px;
      border-radius: 25px;
      height: 90px;

      @include media-xs {
        flex-direction: column;
        height: auto;
      }
    }

    &_name {
      @include additional2($c-white);

      @include media-xs {
        margin-bottom: 15px;
      }
    }
  }
}

.table {
  &_tabs {
    margin-top: 151px;

    @include media-sm {
      margin-top: 80px;
    }

    &_tablist {
      margin-bottom: 27px;
    }
  }

  &_row {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    justify-content: space-between;
    padding: 21px 5px;
    border-bottom: 1px solid #353d6d;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    @include media-md {
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &_titles {
    display: grid;
    grid-template-columns: 20px 123px 178px 117px 88px 220px;
    @include subtitle3($c-white);
    font-size: 16px;
    text-shadow: none;
    padding-bottom: 30px;
    border-bottom: 1px solid #353d6d;
    justify-content: space-between;
    white-space: nowrap;

    @include media-md {
      width: 700px;
      grid-template-columns: 20px 100px 100px 100px 88px 170px;
      font-size: 12px;
    }
  }

  &_rows {
    @include media-md {
      width: 700px;
      font-size: 12px;
    }
  }

  &_img {
    width: 32px;
    height: 30px;
    object-fit: cover;
    margin-right: 12px;
    border-radius: 100%;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 13px;

  @include media-md {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-xs {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  &_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 70px;

    @include media-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-xs {
      grid-template-columns: 1fr;
    }
  }
}

.settings {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-sm {
    width: 100%;
  }

  &_avatar {
    width: 150px;
    height: 150px;
    border: 3px solid $c-white;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_upload {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.form {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  &_name {
    @include subtitle($c-white, 'bold');
    margin-bottom: 20px;
    text-align: center;
  }

  &_btn {
    margin: 30px auto 0 auto;
  }

  &_input {
    width: 100%;
    height: 60px;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    &_wrap {
      text-align: center;
    }

    &_icon {
      cursor: pointer;
      width: 36px;
      height: 36px;
      background-color: $c-dark;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }
}

.hidden {
  display: none;
}
