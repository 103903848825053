@import '../../../styles/mixins.scss';

.panel {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: all $transitionDefaultDuration ease;

  &:hover {
    background-color: rgba(38, 31, 138, 0.5);
  }

  &.active {
    background-color: rgba(38, 31, 138, 0.5);
  }

  &.active {
    img {
      transform: rotate(180deg);
    }
  }

  img {
    transition: all $transitionDefaultDuration ease;
  }

  &_content {
    display: none;

    &.active {
      margin-top: 20px;
      display: block;
    }
  }

  &_top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &_name {
    @include additional($c-white);
  }
}
