@import '../../../styles/mixins.scss';

.item {
  background: rgba(38, 31, 138, 0.5);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;

  &_icon {
    width: 20px;

    img {
      width: 100%;
    }
  }

  &_wrapper {
    text-align: left;
    margin-left: 15px;
  }

  &_name {
    color: $c-grey-300;
    margin-bottom: 5px;
  }

  &_currency {
    color: $c-white;
  }

  &_close {
    width: 20px;
    margin-left: 30px;
    cursor: pointer;
    display: flex;

    img {
      width: 100%;
    }
  }
}
