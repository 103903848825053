@import '../../styles/mixins.scss';

.sidebar {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  padding-top: 25px;
  padding-bottom: 10px;
  overflow-y: auto;
  width: 100%;
  max-width: 64px;
  height: 100vh;
  background: rgba(10, 0, 44, 0.7);
  transition: $transitionDefaultDuration ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(10, 0, 44, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0b1265;
  }

  @include media-md {
    top: 0;
    left: 0;
    transform: translatex(-100%);
    bottom: 0;

    &.active {
      transform: translatex(0);
      transform: translateY(10px);
      max-width: 280px;
    }
  }

  &.active {
    span {
      opacity: 1;
    }

    .sidebar_header {
      img {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
        }
      }
    }

    .sidebar_input {
      width: 100%;
      padding-right: 6px;
    }

    .sidebar_language {
      margin-bottom: 20px;
    }
  }

  &:hover {
    max-width: 280px;

    @include media-md {
      max-width: 280px;
    }

    .sidebar_header {
      img {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
        }
      }
    }

    span {
      opacity: 1;
    }

    .sidebar_input {
      width: 100%;
      padding-right: 6px;
    }
  }

  &_header {
    margin-bottom: 12px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    img {
      &:nth-child(1) {
        display: block;
        width: 133px;
      }

      &:nth-child(2) {
        display: none;
      }
    }
  }

  &_form {
    padding: 5px;
  }

  &_input {
    width: 20px;
    transition: $transitionDefaultDuration ease;

    img {
      margin-left: 3px;
    }

    &_wrap {
      padding: 10px 5px;
    }
  }

  &_language {
    text-transform: uppercase;
    padding-left: 20px;
    margin-top: 50px;
    margin-bottom: 10px;

    &_list {
      width: 80px !important;
      top: -78px;
      left: 5px;
    }
  }
}
