@import '../../styles/mixins.scss';

.property {
  display: flex;

  &_close {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 25px;

    img {
      width: 25px;
    }

    @include media-xs {
      margin-right: 5px;
    }
  }

  &_input {
    width: 100%;
    margin-right: 10px;
  }

  &_inputs {
    display: flex;
    width: 100%;
  }

  &_of {
    color: $c-white;
    margin: 0 10px;
    display: flex;
    align-items: center;
    text-align: center;

    @include media-xs {
      margin: 0 5px;
    }
  }
}
