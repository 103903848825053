@import '../../../styles/mixins.scss';

.item {
  text-align: center;
  width: 196px;
  cursor: pointer;

  @include media-sm {
    width: 95px;
  }

  &_img {
    background: $c-pink;
    border-radius: 10px;
    height: 196px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: auto;
    border: 3px solid $c-pink;

    @include media-sm {
      height: 95px;
      padding: 20px;
      border: 2px solid $c-pink;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.active {
      border: 3px solid $c-white;

      @include media-sm {
        border: 2px solid $c-white;
      }
    }
  }

  &_text {
    @include additional($c-white);
    margin-top: 23px;

    @include media-sm {
      margin-top: 8px;
    }
  }
}
