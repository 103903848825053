@import '../../../styles/mixins.scss';

.tabs {
  width: 70%;

  @include media-md {
    width: 100%;
  }

  &_tablist {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 13px;
    margin-bottom: 30px;

    @include media-sm {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }

    & > li {
      width: 100%;
      cursor: pointer;
      outline: none;
    }
  }

  &_subtitle {
    color: $c-white;
  }

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @include media-sm {
      width: 100%;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.nft {
  width: 25%;

  & > div {
    position: sticky;
    top: 20px;
  }

  @include media-md {
    display: none;
  }
}
