@import '../../../../styles/mixins.scss';

.popup {
  &_review {
    width: 300px;
    margin: auto;
    display: block;
  }

  &_description {
    margin-top: 20px;
    text-align: center;
  }
}

.table {
  padding: 15px;
  border-radius: 10px;
  background-color: $c-dark;
  margin-top: 30px;

  &_item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $c-grey-300;

    &.show {
      justify-content: center;
      @include additional2($c-grey-100);
      cursor: pointer;
      transition: all $transitionDefaultDuration ease;

      &:hover {
        opacity: 0.7;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &_title {
      @include additional2($c-grey-100);
      width: 30%;

      @include media-sm {
        min-width: 50%;
      }
    }
  }
}

.checkbox {
  margin-top: 30px;
}
