@font-face {
  font-family: 'Gemina';
  src: url('../assets/fonts/gemina.ttf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../assets/fonts/Gilroy-Bold.eot'),
    url('../assets/fonts/Gilroy-Bold.ttf') format('opentype'),
    url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../assets/fonts/Gilroy-Medium.eot'),
    url('../assets/fonts/Gilroy-Medium.ttf') format('opentype'),
    url('../assets/fonts/Gilroy-Medium.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('../assets/fonts/Gilroy-SemiBold.eot'),
    url('../assets/fonts/Gilroy-SemiBold.ttf') format('opentype'),
    url('../assets/fonts/Gilroy-SemiBold.woff') format('woff');
  font-style: normal;
}
