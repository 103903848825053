@import '../../../../styles/mixins.scss';

.back {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);

  img {
    width: 100%;
    height: 100%;
  }
}

.btn {
  width: 100%;
  margin-top: 50px;
}

.input {
  margin-bottom: 10px;

  &_inner {
    display: flex;
    align-items: center;
  }

  &_wrap {
    width: 100%;
  }
}

.select {
  min-width: 30%;
  margin-right: 10px;
  z-index: 2;

  @include media-sm {
    min-width: 40%;
  }
}

.label {
  margin-bottom: 15px;
  display: block;
  user-select: none;
  @include subtitle($c-white, 'bold');
}

.datepicker {
  position: relative;
  z-index: 1;

  &_input {
    width: 100%;
    height: 53px;
  }
}
