@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  background: rgba(38, 31, 138, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 20px;

  &:hover .item_img {
    transform: scale(1.2);
  }

  &_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 30px;
    transition: 1s;
  }

  &_wrapper {
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 10px;
  }

  &_title {
    @include subtitle($c-white, 'bold', 26px);
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_price {
    color: $c-white;
  }
}
