@import '../../../styles/mixins.scss';

.item {
  width: 100%;
  background: linear-gradient(
    112.91deg,
    #3323b7 -16.25%,
    #5220b0 33.12%,
    #661eab 81.07%,
    #7c1da6 121.14%
  );
  border-radius: 25px;
  padding: 40px;
  position: relative;
  overflow: hidden;

  @include media-xs {
    text-align: center;
  }

  &_name {
    @include subtitle2($c-white);
    margin-bottom: 20px;
  }

  &_text {
    color: $c-white;
    margin-bottom: 20px;
  }

  &_image {
    position: absolute;
    right: 0;
    bottom: 0;

    @include media-xs {
      position: static;
      margin-top: 30px;
    }
  }
}
