@import '../../styles/mixins.scss';

.footer {
  background: #060028;
  overflow: hidden;
}

.container {
  @include container();
}

.wrapper {
  &_children {
    position: relative;
    padding-top: 60px;

    @include media-md {
      padding-top: 90px;
    }
  }
}

.bg {
  background: url('../../assets/images/bg-stars.png');
  background-repeat: repeat-y;
  background-size: 100%;
}
