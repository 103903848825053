@import '../../../styles/mixins.scss';

.item {
  color: $c-white;
  border: 1px solid $c-white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  &_type {
    @include additional3($c-pink);
    margin-bottom: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
