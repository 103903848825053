@import '../../../styles/mixins.scss';

.cost {
  display: flex;
  justify-content: space-between;
  background-color: $c-dark;
  border-radius: 10px;
  padding: 20px;

  @include media-xs {
    flex-direction: column;
  }

  &_subtitle {
    @include additional2($c-white, 'medium');
    margin-bottom: 5px;
  }

  &_num {
    @include subtitle($c-white, 'bold');
  }

  &_btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include media-xs {
      margin-top: 15px;
    }
  }
}
