@import '../../../styles/mixins.scss';

.popup {
  &_close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  &_title {
    text-align: center;
    margin-bottom: 20px;
  }

  &_description {
    @include additional($c-grey-100);
    margin-bottom: 40px;
    text-align: center;
  }
}

:global {
  // Popup animation
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  .popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    background: linear-gradient(
      87.96deg,
      #0b1265 -76.77%,
      #0d1361 104.4%,
      #05062c 235.88%
    ) !important;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    border: none !important;
    padding: 64px !important;

    @include media-md {
      width: 90% !important;
      padding: 64px 20px !important;
    }
  }
}
