@import '../../../styles/mixins.scss';

.item {
  background: $c-dark;
  border-radius: 25px;
  transition: $transitionDefaultDuration all ease;
  width: 100%;

  &_text {
    color: $c-white;
    line-height: 25px;
    padding-bottom: 20px;
  }

  &_body {
    max-height: 0;
    transition: $transitionDefaultDuration max-height ease;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
  }

  &_title {
    padding: 25px 40px 25px 75px;
    cursor: pointer;
    position: relative;
    @include additional($c-white);

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: 30px;
      width: 22px;
      height: 24px;
      background: url('../../../assets/icons/plus-minus.png') 0 0 no-repeat;
    }
  }

  &.active {
    background: $c-pink;

    .item_title:before {
      background-position: 0 100%;
    }
  }
}
