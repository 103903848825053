@import '../../../styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: rgba(38, 31, 138, 0.5);
  @include additional2($c-white, 'semibold');
  cursor: pointer;

  &.active {
    background: $c-pink;
  }
}
