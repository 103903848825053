@import '../../../../styles/mixins.scss';

.links {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}

.input {
  border-radius: 0;

  &_icon {
    height: 25px;
  }
}
