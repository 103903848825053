@import '../../../styles/mixins.scss';

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter {
  width: 30px;
  margin-right: 30px;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.input {
  width: 50%;
  margin-right: 10px;
}

.select {
  width: 30%;
  margin-right: 10px;

  @include media-sm {
    width: 70%;
  }
}

.sidebar {
  width: 300px;
  margin-right: 20px;
  background-color: #050641;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  min-width: 300px;

  @include media-sm {
    width: 100%;
    margin-bottom: 10px;
  }
}

.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  label {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &_label {
    @include additional2($c-white);
  }
}

.panel {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.wrapper {
  margin-top: 20px;
  display: flex;

  @include media-sm {
    flex-direction: column;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 13px;
  width: 100%;
  height: 100%;

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-sm {
    grid-template-columns: 1fr;
  }
}
