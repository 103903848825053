@import '../../styles/mixins.scss';

.container {
  @include container();
  padding-bottom: 500px;
  z-index: 2;
  position: relative;

  @include media-md {
    padding-bottom: 200px;
  }
}

.content {
  margin-top: 40px;
}

.h2 {
  margin-bottom: 42px;
  text-align: center;

  @include media-md {
    margin-top: 0;
  }
}

.item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
